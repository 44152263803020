export enum Modules  {
  HERCULE= 1,
  HECTOR= 2,
  ULYSSE= 3,
  DIONYSOS= 4
}

export interface Module {
  [key: string]: string | number ;
  CODE: number;
  DESIGNATION: string;
}